
const onChangeHandler = (setState, prop, e) => {

    const data = {};
    data[prop] = e.target.value;

    setState(data);

 }

 export default onChangeHandler;