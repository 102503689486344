import axios from 'axios';
import { api_base_url } from '../../../data/client';
import { navigate } from 'gatsby';
import is_email_valid from '../../../utils/is_email_valid';

const no_email_message = 'Please provide an email';

const onSubmitHandler = async (
  { setState, state, setUserGlobalState, setFeedbackState },
  e
) => {
  try {
    const { email, password } = state;

    e.preventDefault();

    if (!email) {
      setFeedbackState({
        isActive: true,
        message: no_email_message,
        isLoading: false,
        isError: true
      });
    } else if (!is_email_valid(email)) {
      setFeedbackState({
        isActive: true,
        isLoading: false,
        message: 'Please provide a valid email',
        isError: true
      });
    } else if (!password) {
      setFeedbackState({
        isActive: true,
        message: 'Please provide a password',
        isError: true,
        isLoading: false
      });
    } else {
      setFeedbackState({
        isActive: true,
        isError: false,
        isLoading: true
      });

      const res = await axios({
        url: api_base_url + '/users/auth/login',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        },
        data: JSON.stringify(state)
      });

      setFeedbackState({
        isActive: true,
        message: 'Success',
        isError: false,
        isLoading: false
      });

      const user = res.data.user;

      if (!res.data.error) {
        localStorage.setItem('s_t_kmp', res.data.token);

        setUserGlobalState(res.data);

        navigate('/dashboard');
      } else {
        setFeedbackState({
          isActive: true,
          isError: true,
          isLoading: false,
          message: res.data.message
        });
      }
    }
  } catch (err) {
    setFeedbackState({
      isActive: true,
      isError: true,
      isLoading: false,
      message: 'Unexpected error, contact us'
    });
  }
};

export default onSubmitHandler;
