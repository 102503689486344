import React, { useContext } from 'react';
import { Link } from 'gatsby';
import useSetState from '../../utils/useSetState';
import onSubmitHandler from './utils/onSubmitHandler';
import onChangeHandler from './utils/onChangeHandler';
import { UserContext } from '../UserContext/UserContext';
import { FeedbackContext } from '../context/FeedbackContext/FeedbackContext';
import { LangContext } from '../context/LangContext';

const Login = () => {
  const { translations } = useContext(LangContext);

  const [state, setState] = useSetState({
    email: '',
    password: '',
    isLoading: false
  });

  const { setUserGlobalState } = useContext(UserContext);
  const { setFeedbackState } = useContext(FeedbackContext);

  return (
    <div className="page-contents">
      <div className="utility-page-wrap bg-gray-1">
        <div className='container'>
          <div className="content-width-medium align-center">
            <div className="form-heading account-form-heading">
              <h1 className="display-heading-2">{translations.loginTitle}</h1>
              <div className="large-text">{translations.loginSubtitle}</div>
            </div>
            <div className="form-block w-form">
              <form
                onSubmit={onSubmitHandler.bind(null, {
                  setState,
                  state,
                  setUserGlobalState,
                  setFeedbackState
                })}
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="form-grid-vertical"
              >
                <input
                  onChange={onChangeHandler.bind(null, setState, 'email')}
                  value={state.email}
                  type="email"
                  className="form-input form-input-large form-input-dark w-input"
                  name="email"
                  data-name="Email"
                  placeholder={translations.emailPlace}
                  id="email"
                />
                <input
                  onChange={onChangeHandler.bind(null, setState, 'password')}
                  value={state.password}
                  type="password"
                  className="form-input form-input-large form-input-dark w-input"
                  name="Password"
                  data-name="Password"
                  placeholder={translations.passwordPlace}
                  id="Password"
                />
                <input
                  type="submit"
                  value={translations.sumbitBtnText}
                  data-wait="Please wait..."
                  className="button button-large bg-primary-4 w-button"
                />
                <Link to={translations.forgetPasswordLink}>
                  <div href="#" className="small-text text-white">
                    {translations.forgetPasswordText}
                  </div>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
