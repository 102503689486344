import React from 'react';
import Login from '../containers/Login/Login';
import PageWrapper from '../containers/PageWrapper';
import WebsiteWrapper from '../parts/WebsiteWrapper';

const LoginPage = ({ pageContext , location}) => {
  const { lang, translations } = pageContext;

  return (
    <PageWrapper lang={lang} translations={translations}>
      <WebsiteWrapper isNavBgWhite={true} location={location}>
        <Login />
      </WebsiteWrapper>
    </PageWrapper>
  );
};

export default LoginPage;
